document.addEventListener("DOMContentLoaded", function () {
    "use strict";

    new WOW().init();


    //**************Scroll-To-Top Button **************//

    $(window).scroll(function () {
      $(this).scrollTop() >= 100
          ? $("#return-to-top").fadeIn(200)
          : $("#return-to-top").fadeOut(200);
  }),
      $("#return-to-top").click(function () {
          $("body,html").animate(
              {
                  scrollTop: 0,
              },
              500
          );
      });


    /*--------------------------------------
  Preloader
  --------------------------------------*/
  $(window).on('load', function () {
    $('#loader-wrap').delay(200).fadeOut();
  });

    //lazy load
    $(function () {
        $("img.lazy").lazyload({
            effect: "fadeIn"
        });

    });





  //video popup


  $('.popup-gallery').find('a.popup').magnificPopup({
    type: 'iframe',
    gallery: {

		  enabled:false

		}
  });








});
